export const dateFilter = (value, format = 'dateshort', locale = 'ru-ru') => {

    if (!locale) {
        locale = 'ru-RU'
    }

    const options = {}
    if (format.includes('datetimeshort')) {
        options.day = '2-digit';
        options.month = '2-digit';
        options.year = 'numeric';
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
    } else if (format.includes('dateshort')) {
        options.day = '2-digit';
        options.month = '2-digit';
        options.year = 'numeric';
    } else if (format.includes('date')) {
        options.day = '2-digit';
        options.month = 'long';
        options.year = 'numeric';
    }
    if (format.includes('time')) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
    }
    return new Intl.DateTimeFormat(locale, options).format(new Date(value));
}
