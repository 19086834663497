<template>
  <tr>
    <td>{{ date }}</td>
    <td>{{ event.name }}</td>
    <td>
      <a href="" @click.prevent="showModal" class="btnn">{{ p1 }}</a>
    </td>
    <td>
      <a href="" @click.prevent="showModal" class="btnn">{{ X }}</a>
    </td>
    <td>
      <a href="" @click.prevent="showModal" class="btnn">{{ p2 }}</a>
    </td>
    <td>
      {{ f1Param }}
    </td>
    <td>
      <a href="" @click.prevent="showModal" class="btnn">{{ f1Value }}</a>
    </td>
    <td>
      {{ f2Param }}
    </td>
    <td>
      <a href="" @click.prevent="showModal" class="btnn">{{ f2Value }}</a>
    </td>
    <td>
      {{ total }}
    </td>
  </tr>
</template>

<script>
import {dateFilter} from "@/utils/date";

export default {
  name: "EventItem",
  props: {
    event: Object
  },
  computed: {
    p1() {
      return this.event.markets.find(el => el.name === 'П1')
          ? parseFloat(this.event.markets.find(el => el.name === 'П1')?.value)
          : '-'
    },
    X() {
      return this.event.markets.find(el => el.name === 'Х')
          ? parseFloat(this.event.markets.find(el => el.name === 'Х')?.value)
          : '-'
    },
    p2() {
      return this.event.markets.find(el => el.name === 'П2')
          ? parseFloat(this.event.markets.find(el => el.name === 'П2')?.value)
          : '-'
    },
    f1() {
      return this.event.markets.find(el => el.name === 'К1') ?? null
    },
    f2() {
      return this.event.markets.find(el => el.name === 'К2') ?? null
    },
    f1Param() {
      return this.f1?.param ?? '-'
    },
    f2Param() {
      return this.f2?.param ?? '-'
    },
    f1Value() {
      return this.f1?.value ?? '-'
    },
    f2Value() {
      return this.f2?.value ?? '-'
    },
    total() {
      return this.event.markets.find(el => el.name === 'М') ? this.event.markets.find(el => el.name === 'М').param : '-'
    },
    date() {
      return dateFilter(this.event.start, 'datetimeshort')
    }
  },
  methods: {
    showModal() {
      this.$emit('show-modal')
    }
  }
}
</script>

<style scoped>

</style>