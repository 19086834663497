<template>
  <div id="app">
    <hello-world />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
@import "css/bootstrap.min.css";
@import "css/main.css";

#app {

}
</style>
