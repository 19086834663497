<template>
  <div>

    <div class="preloader" :class="{ 'd-none' : contentLoaded }">
      <div class="spinner">
        <span class="spinner-rotate"></span>
      </div>
    </div>

    <div class="my-modal-wrap" :class="{ opened }" @click="opened = false">

      <div class="my-modal" @click.stop>

        <button class="my-modal__close" @click="opened = false">&#x2715;</button>

        <p class="my-modal__title">Сайт находиться в разработке</p>
        <p class="my-modal__desc">До релиза осталось совсем чуть-чуть</p>

      </div>

    </div>

    <header class="header-area gradient-bg">
      <nav class="navbar navbar-expand-lg main-menu">
        <div class="container-fluid">

          <a class="navbar-brand" href="/"><img src="/images/logo.svg" class="d-inline-block align-top"
                                                alt=""></a>

          <button class="navbar-toggler" type="button" @click="menu = !menu" data-toggle="collapse" data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="menu-toggle" :class="{ 'is-active' : menu }"></span>
          </button>

          <div class="navbar-collapse" :class="{ collapse: !menu }" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
              <li class="nav-item"><a class="nav-link" href="/" @click.prevent="showModal">Главная</a></li>
              <li class="nav-item"><a class="nav-link" href="/about" @click.prevent="showModal">О нас</a></li>
              <li class="nav-item"><a class="nav-link" href="/lines" @click.prevent="showModal">Линия</a></li>
              <li class="nav-item"><a class="nav-link" href="/news" @click.prevent="showModal" role="button">Новости</a>
              </li>
              <li class="nav-item"><a class="nav-link" href="/contacts" @click.prevent="showModal">Контакты</a></li>
            </ul>
            <div class="header-btn justify-content-end">
              <a href="register" @click.prevent="showModal" class="bttn-small btn-fill"><i class="fa fa-key"></i>Регистрация</a>
              <a href="login" @click.prevent="showModal" class="bttn-small btn-fill ml-2"><i class="fa fa-lock"></i>Войти</a>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12">
            <aside class="content-sidebar">
              <h3>Чемпионаты</h3>
              <ul class="d-none d-md-block">
                <li v-for="champ in champsShort" :key="champ.id">
                  <a href="" @click.prevent="changeChamp(champ.id)">{{ champ.name }}</a>
                </li>
              </ul>

              <div class="d-block d-md-none">
                <select class="custom-select" name="Чемпионаты" @change="changeChamp">
                  <option v-for="champ in champsShort" :key="champ.id" :value="champ.id">{{ champ.name }}</option>
                </select>
              </div>

            </aside>

            <aside class="content-sidebar pt-0 d-block d-xl-none">
              <h3>Спорт</h3>
              <ul class="d-none d-md-block">
                <li v-for="sport in sports" :key="sport.id" @click.prevent="changeSport(sport.id)">
                  <a href="#">
                    {{ sport.name }}
                  </a>
                </li>
              </ul>

              <div class="d-block d-md-none">
                <select class="custom-select" name="Спорт" @change="changeSport">
                  <option v-for="sport in sports" :key="sport.id" :value="sport.id">{{ sport.name }}</option>
                </select>
              </div>

            </aside>

          </div>
          <div class="col-xl-8 col-lg-10 col-md-9">

            <carousel class="main-content-slider" :items="1" :nav="false">
              <div class="single-slider" style="background: url('/images/banner/hero-2.jpg') no-repeat center / cover;">
                <div class="row">
                  <div class="col-xl-6">
                    <h3>Победа с Ольгой Бузовой</h3>
                    <p>Выигрышная ставка – это ставка, которая в случае успеха принесет выигрыш на величину, равную или
                      превышающую размер ставки.</p>

                    <div class="hero-score">Риск <span>2.5</span></div>
                    <div class="hero-score">122+ Ставок</div>
                  </div>
                </div>
              </div>
              <div class="single-slider" style="background: url('/images/banner/hero-1.jpg') no-repeat center / cover;">
                <div class="row">
                  <div class="col-xl-6">
                    <h3>Лучшие коэффициенты</h3>
                    <p>У нас нет никаких договорных матчей!
                      Мы не даём бесплатные прогнозы, только платные и самые высокие коэффициенты!</p>
                    <div class="hero-score">Риск <span>1.5</span></div>
                    <div class="hero-score">721+ Ставок</div>
                  </div>
                </div>
              </div>
            </carousel>

            <div class="game-box">

              <div class="card" v-if="champ">
                <div class="card-header">
                  <h3>{{ champ.champ.name }}</h3>
                </div>
                <div class="card-body">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" @click.prevent="showModal" href="#home" role="tab"
                         aria-controls="home" aria-selected="true">Сегодня</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" @click.prevent="showModal" href="#profile" role="tab"
                         aria-controls="profile" aria-selected="false">Завтра</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" @click.prevent="showModal" href="#contact" role="tab"
                         aria-controls="contact" aria-selected="false">Все матчи</a>
                    </li>
                    <li class="search-game">
                      <input type="text" placeholder="Поиск" @keydown.enter="showModal">
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <table class="table table-hover table-borderless table-striped">
                        <thead>
                        <tr>
                          <th scope="col">Дата</th>
                          <th scope="col">Команды</th>
                          <th scope="col">П1</th>
                          <th scope="col">X</th>
                          <th scope="col">П2</th>
                          <th scope="col">Ф1</th>
                          <th scope="col"></th>
                          <th scope="col">Ф2</th>
                          <th scope="col"></th>
                          <th scope="col">Тотал</th>
                        </tr>
                        </thead>
                        <tbody>
                        <event-item
                            v-for="event in champ.events"
                            :key="event.id"
                            :event="event"
                            @show-modal="showModal"
                        />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 d-none d-xl-block">
            <!--            <div class="web-sidebar-widget">-->
            <!--              <div class="widget-head">-->
            <!--                <h3>BUZBET реклама</h3>-->
            <!--              </div>-->
            <!--              <div class="widget-body p-0">-->
            <!--                <a href=""><img class="w-100" src="/images/ads.jpg" alt=""></a>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="web-sidebar-widget">
              <div class="widget-head">
                <h3>BUZBET инфо</h3>
              </div>
              <div class="widget-body">
                <p><strong>Адрес:</strong> 123290, Россия, город Москва, Шелепихинское шоссе, дом 11/2, этаж цоколь,
                  пом. I, комната 18</p>
                <p><strong>Сокращенное фирменное наименование:</strong> ООО "БК "BUZBET"</p>
                <p><strong>Фирменное наименование:</strong> Общество с ограниченной ответственностью "БК "BUZBET"</p>
                <p>Мы осуществляем деятельность по приему ставок на спортивные события на основании Лицензии № 26 от
                  25.12.2012г.</p>
                <p><strong>Email:</strong> info@buzbet.ru</p>
              </div>
            </div>
            <div class="web-sidebar-widget">
              <div class="widget-head">
                <h3>BUZBET чат</h3>
              </div>
              <div class="widget-body">
                <div class="chat-boxed">
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Семен <span>{{ currentDate }}</span></h4>
                      <p>Ставки заходят на ура, поставил уже на многое)
                        На данный момент ставка зашла с коэффициентом 1.28
                        Что было в прошлый раз?</p>
                    </div>
                  </div>
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Демьян <span>{{ currentDate }}</span></h4>
                      <p>Отличные кэфы на чемпионат европы, тут и победа и тотал больше 2.5 и тотал 2.5 меньше.</p>
                    </div>
                  </div>
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Юлия <span>{{ currentDate }}</span></h4>
                      <p>Вчера поднял 100к, поставил на П1 Дания - Россия. Россия пролетела как и ожидалось.</p>
                    </div>
                  </div>
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Илья <span>{{ currentDate }}</span></h4>
                      <p>Дания играет хорошо, но Россия намного сильнее.
                        Жду разгрома, в среднем Дания забивает 1 гол за матч.
                        Думаю что сегодня, Россия забьет как минимум 2 гола, да и вообще если датчане смогут распечатать ворота России то это уже победа.
                        По статистике датчане забивают по 2 гола за матч, а россияне забивают минимум 3. И еще!
                        В России играют сильнейшие футболисты, в том числе и Дзюба!</p>
                    </div>
                  </div>
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Глебушка <span>{{ currentDate }}</span></h4>
                      <p>Португалия - Германия, проиграл ставку в конторе (из того, что помню) и теперь там должны вернуть деньги, однако этого не происходит уже больше месяца!
                        Не могу ни как забрать с них деньги</p>
                    </div>
                  </div>
                  <div class="single-chat">
                    <div class="chat-text">
                      <h4>Виталий <span>{{ currentDate }}</span></h4>
                      <p>Не ожидал что букмейкерская контора от Оленьки будет такой распиаренной, но всё же. Играть можно, кэфы норм, деньги выводят сразу. В целом по игре всё устраивает.
                        Но есть один очень существенный минус.
                        Это то, что в лайве практически не играют.
                        Как только ты начинаешь крутить на высоких кэфах, залетает много интересных матчей, в которые просто невозможно проиграть.
                        Из-за этого люди не хотят ставить и даже не заходят в лайв.
                        Приходится крутить и ставить в лайве без риска.</p>
                    </div>
                  </div>
                  <a href="" @click.prevent="showModal" class="bttn-small btn-fill w-100 centered">Войти в чат</a>
                </div>
              </div>
            </div>
            <aside class="content-sidebar pt-0">
              <h3>Спорт</h3>
              <ul>
                <li v-for="sport in sports" :key="sport.id" @click.prevent="changeSport(sport.id)">
                  <a href="#">
                    {{ sport.name }}
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </section>

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
            <ul>
              <li><a @click.prevent="showModal" href="">FAQ</a></li>
              <li><a @click.prevent="showModal" href="">Политика конфиденциальности</a></li>
              <li><a @click.prevent="showModal" href="">Правила игры</a></li>
              <li><a @click.prevent="showModal" href="">Условия использования</a></li>
              <li><a @click.prevent="showModal" href="">Поддержка</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import axios from 'axios'
import EventItem from "@/components/EventItem";
import {dateFilter} from "@/utils/date";

export default {
  name: 'HelloWorld',
  components: {EventItem, carousel},
  data() {
    return {
      champ: null,
      champs: [],
      sports: [],
      opened: false,
      menu: false,
      contentLoaded: false
    }
  },
  methods: {
    showModal() {
      this.opened = true
    },
    async getChamps(id) {
      await axios.get(`https://www.olimp.bet/apiru/prematch/sport/?id=${id}`)
          .then(res => {
            this.champs = res.data.champs

            this.changeChamp(res.data.champs[0].id)

          })
    },
    async getSports() {
      await axios.get('https://www.olimp.bet/apiru/live/sport/list/')
          .then(res => {
            this.sports = res.data
          })
    },
    async changeChamp(id) {
      const newId = id?.target?.value ? id?.target?.value : id
      await axios.get(`https://www.olimp.bet/apiru/prematch/champ/?id=${newId}`)
          .then(res => {
            this.champ = res.data
          })
    },
    changeSport(id) {
      const newId = id?.target?.value ? id?.target?.value : id
      this.getChamps(newId)
    }
  },
  computed: {
    champsShort() {
      return this.champs
      // return [...this.champs].splice(0, 35)
    },
    currentDate() {
      return dateFilter(new Date())
    }
  },
  async created() {

    await this.getChamps(1);

    await this.getSports();

    // this.changeChamp(13754);

    this.contentLoaded = true

  }
}
</script>

<style scoped>

.my-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.my-modal-wrap.opened {
  z-index: 100;
  visibility: visible;
  opacity: 1;
}

.my-modal {
  text-align: center;
  color: #292929;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #FDD50B;
  padding: 40px;
}

.my-modal__title {
  font-weight: 600;
  font-size: 30px;
}

.my-modal__desc {
  font-size: 20px;
  margin-bottom: 0;
}

.my-modal__close {
  position: absolute;
  right: -40px;
  top: -40px;
  width: 30px;
  height: 30px;
  font-size: 50px;
  color: #FDD50B;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
}

.my-modal__close:hover {
  opacity: 0.7;
  transition: 0.3s;
}

</style>
